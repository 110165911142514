/* You can add global styles to this file, and also import other style files */

body {
  height: 100svh;
}

@media (max-width: 550px),
  (max-width: 500px),
  (max-width: 450px),
  (max-width: 400px),
  (max-width: 360px),
  (max-width: 280px) {
  .modal-fullscreen .modal-content {
    height: 100svh;
  }
}

html,
body {
  height: 100%;
}

.modal-header .btn-close {
  padding: 0px !important;
  margin: 0px !important;
}

.modal-header > .title {
  width: auto !important;
}

/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
